<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-center mb-2">
            <h3 class="mb-0">
              <router-link to="/">
                <v-img
                  width="200"
                  src="@/assets/images/logo.png"
                  class="mx-auto"
                />
              </router-link>
            </h3>
          </div>
          <div class="text-center mb-10">
            <h3 class="mb-3">
              {{ $t("Réinitialiser le mot de passe") }}
            </h3>
            <p class="mb-2">
              {{ $t("Saisissez votre adresse électronique et nous vous enverrons les instructions pour réinitialiser votre mot de passe.") }}
            </p>
          </div>
          <v-form
            ref="registerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="onSubmit"
          >
            <div class="mb-4">
              <p class="text-14 mb-1">
                {{ $t('Entrez votre e-mail') }}
              </p>
              <v-text-field
                v-model="email"
                placeholder="example@mail.com"
                outlined
                :error-messages="errorMessages.email"
                :rules="emailRules"
                :label="$t('Email')"
                dense
                hide-details="auto"
                class="mb-4"
              />
            </div>

            <div class="mb-10">
              <v-btn
                :disabled="!valid"
                block
                color="primary"
                class="text-capitalize font-600"
                type="submit"
                :loading="loading"
              >
                {{ $t("Envoyer le lien de réinitialisation") }}
              </v-btn>
            </div>
          </v-form>
        </div>
        <div class="py-4 grey lighten-2">
          <div class="text-center">
            <span class="grey--text text--darken-1">{{ $t("Vous avez déjà un compte ?") }} <router-link
              to="/login"
              class="ms-2 grey--text text--darken-4 font-600"
            >{{ $t("Connectez-vous") }}</router-link> </span>
          </div>
        </div>
        <v-snackbar
          v-model="isSnackbarBottomVisible"
          :color="snackBarColor"
          :light="$vuetify.theme.dark"
          timeout="5000"
        >
          {{ snackBarMessage }}
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { i18n } from '@/plugins/i18n'

  export default {

    data () {
      return {
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        errorMessages: { name: 'Est requis' },
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],

      }
    },
    methods: {
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return
        this.loading = true
        axios({
          method: 'post',
          url: '/forgot-password',
          data: { email: this.email },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        })
          .then((response) => {
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              // ? Set user's data in localStorage for UI/Other purpose
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                this.errorMessages = { ...response.data.errors }
              } else {
                this.isSnackbarBottomVisible = true
                this.snackBarMessage = response.data.message
                this.snackBarColor = this.$vuetify.theme.currentTheme.error
              }
              return false
            }

            this.isSnackbarBottomVisible = true
            this.snackBarColor = this.$vuetify.theme.currentTheme.success
            this.snackBarMessage = this.$t('Nous vous avons envoyé par email un lien pour réinitialiser votre mot de passe')
          })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Reset!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media(max-width: 500px){
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media(max-width: 500px){
      padding: 3rem 1rem 0px;
    }
  }
}

</style>
